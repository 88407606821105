// @flow
import {combineEpics} from 'redux-observable'
import {ajax} from 'rxjs/observable/dom/ajax'
import {push, replace} from 'react-router-redux'
import sozEpics from '../store/soz.epics'

const notFoundRedirect = () => {
  window.location.assign('/404-not-found')
}

const combinedEpics = combineEpics(
  ...sozEpics
)

const browserDependencies = {
  ajax,
  push,
  replace,
  notFoundRedirect,
}

const rootEpic = (...args: any) => {
  return combinedEpics(...args, browserDependencies)
}

export {combinedEpics}
export default rootEpic
