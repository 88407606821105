// @flow
import {combineReducers} from 'redux'

import soz from '../store/soz.reducer'
import footnote from '../../commons/components/footnote/store/footnote.reducer'

const reducers = {
  soz,
  footnote,
}

export default combineReducers(reducers)
