const webcTheme = {
  fontStyles: {
    alt: {
      fontFamily: 'Rubik',
      fontWeight: '500',
    },
  },
  fontSizes: {
    verySmall: '10px',
    small: '12px',
    text: '15px',
    veryBig: '18px',
    extraBig: '22px',
  },
  colors: {
    textInverted: 'white',
    textHighlight: 'red',
    bgMarkedText: 'yellow',
    bgFussnote: 'red',
  },
  spacing: {
    space0: 0,
    space1: '0.25rem',
    space2: '0.5rem',
    space3: '0,75rem',
    space4: '1.25rem',
  },
}

export default webcTheme

