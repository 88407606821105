// @flow
import {combineEpics} from 'redux-observable'
import {ajax} from 'rxjs/observable/dom/ajax'
import {push} from 'react-router-redux'
import drdaEpics from '../store/drda.epics'

const combinedEpics = combineEpics(
  ...drdaEpics
)

const browserDependencies = {
  ajax,
  push,
}

const rootEpic = (...args: any) => {
  return combinedEpics(...args, browserDependencies)
}

export {combinedEpics}
export default rootEpic
