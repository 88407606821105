export const CLASS_NAME_READER = 'reader'
export const DIGITAL_CSS_CLASSNAME = 'cp_digital'
export const DIGITAL_DEBUG_CSS_CLASSNAME = 'cp_debug'
export const DIGITAL_ENABLE_LAW_LINKS = 'cp_law-links'

const C = `.${DIGITAL_CSS_CLASSNAME}`
const C_DEBUG = `.${DIGITAL_CSS_CLASSNAME}.${DIGITAL_DEBUG_CSS_CLASSNAME}`
const C_HP = `.${DIGITAL_CSS_CLASSNAME}.homepage`
const C_LAW = `.${DIGITAL_CSS_CLASSNAME}.${DIGITAL_ENABLE_LAW_LINKS}`

const debugColors = {
  normGrp: 'green',
  normel: 'orange',
  textgr: 'blue',
  listenTitel: 'darkpink',
}

const applyBaseRules = (renderer, theme) => {
  const styles = `
    mark {
      background-color: ${theme.colors.bgMarkedText};
      color: inherit;
    }
    body {
      overflow-y: scroll;
    }
    body.${CLASS_NAME_READER} {
      overflow-y: hidden;
    }
  `
  renderer.renderStatic(styles)
}

const applyBasePrintRules = (renderer) => {
  renderer.renderStatic(`
    @media print {
      * {
        color: black;
      }
    }
  `)
}

const applyLinkRules = (renderer) => {
  const linkStyles = `
    ${C_LAW} a[data-law] {
      cursor: pointer;
    }
  `
  renderer.renderStatic(linkStyles)
}

// see https://confluence.oegbverlag.at/pages/viewpage.action?pageId=67045716
const applyTitelbereichRules = (renderer, theme) => {
  // reset margin and padding
  const resetStyles = `
    ${C} .titelbereich .titel * {
      display: inline;
      margin: 0;
      padding: 0;
    }
  `
  renderer.renderStatic(resetStyles)
  const titelnumSpacingRules = `
    ${C} .titelbereich .titel .titelnum::after {
      content: ' ';
  }`
  renderer.renderStatic(titelnumSpacingRules)

  // untertitel
  const untertitelStyles = `
    ${C} .titelbereich .untertitel {
      text-align: center;
      font-weight: bold;
    }
  `
  renderer.renderStatic(untertitelStyles)

  // h1
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.extraBig,
    },
    `${C} .titelbereich.h1 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
    },
    `${C} .titelbereich.h1`
  )

  // h2
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.veryBig,
    },
    `${C} .titelbereich.h2 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
      paddingTop: theme.spacing.space3,
    },
    `${C} .titelbereich.h2`
  )

  // h3
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.big,
    },
    `${C} .titelbereich.h3 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
      paddingTop: theme.spacing.space3,
    },
    `${C} .titelbereich.h3`
  )

  // h4
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.text,
    },
    `${C} .titelbereich.h4 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
      paddingTop: theme.spacing.space3,
    },
    `${C} .titelbereich.h4`
  )

  // h5
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.text,
    },
    `${C} .titelbereich.h5 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
      paddingTop: theme.spacing.space3,
    },
    `${C} .titelbereich.h5`
  )

  // h6
  renderer.renderStatic(
    {
      ...theme.fontStyles.alt,
      fontSize: theme.fontSizes.text,
    },
    `${C} .titelbereich.h6 .titel *`
  )
  renderer.renderStatic(
    {
      paddingBottom: theme.spacing.space2,
      paddingTop: theme.spacing.space3,
    },
    `${C} .titelbereich.h6`
  )
}

const applyNormelStyles = (renderer, theme) => {
  const styles = `
    ${C} .normel .num, 
    ${C} .normel .titel 
    {
      font-weight: bold;
    }
    ${C_DEBUG} .normel .num,
    ${C_DEBUG} .normel .titel 
    {
      color: ${debugColors.normel};
    }
    ${C} .normel .num + .titel {
      display: inline;
    }
    ${C} .normel .num::after {
      content: ' ';
    } 
    ${C} .normel .num + p {
      display: inline;
    }
    ${C} .normel .titel + p {
      margin-top: ${theme.spacing.space2};
      margin-bottom: ${theme.spacing.space1};
    }
    ${C} .normel {
      margin-bottom: ${theme.spacing.space2};
    }
    ${C} .normel .titel + .normgrp {
      margin-top: ${theme.spacing.space2};
      margin-bottom: ${theme.spacing.space1};
    }

    ${C} .normgrp > .titel {
      font-weight: bold;
    }
    ${C_DEBUG} .normgrp > .titel {
      color: ${debugColors.normGrp};
    }
    
    ${C} .normgrp.normgrp-level-1 > .titel {
      font-size: ${theme.fontSizes.big};
      padding-bottom: ${theme.spacing.space1};
      padding-top: ${theme.spacing.space2};
    }
  `
  renderer.renderStatic(styles)
}

const applyTextGrpStyles = (renderer, theme) => {
  const styles = `
    ${C} .textgr {
      margin-top:0;
      margin-bottom: 0;
    }
    ${C} .textgr .textgr {
      /* margin-left: 20px; */
    }
    ${C} .textgr .text-grtit {
      font-weight: bold;
      margin-top: ${theme.spacing.space3};
    }
    ${C_DEBUG} .textgr .text-grtit {
      color: ${debugColors.textgr};
    }
    ${C} .textgr .text-grobertitel + .text-grtit {
      margin-top: 0px;
    }
    ${C} .textgr .text-grobertitel {
      margin-top: ${theme.spacing.space3};
    }
    ${C_DEBUG} .textgr .text-grobertitel {
      color: ${debugColors.textgr};
    }
    ${C_DEBUG} .textgr .text-gruntertitel {
      color: ${debugColors.textgr};
    }
    ${C} .textgr div + p {
      margin-top: ${theme.spacing.space2};    
    }
  `
  renderer.renderStatic(styles)
}

const applyListeStyles = (renderer, theme) => {
  const styles = `
    ${C} .liste {
      margin-bottom: ${theme.spacing.space1};
      margin-left: 0;
      padding-left: 0;
    }
    ${C} .liste .listen_titel {
      font-weight: bold;
      margin-top: ${theme.spacing.space2};
      margin-bottom: ${theme.spacing.space1};
    }
    ${C_DEBUG} .liste .listen_titel {
      color: ${debugColors.listenTitel};
    }
    ${C} .liste li {
      margin-left: 20px;
      padding-left: 5px;
    }
    ${C} .nummerierung-none li {
      padding-left: 0;
      margin-left: 0;
      list-style-type: none;
    }
    ${C} ul.liste.nummerierung-circle li {
      list-style-type: circle;
    }
    ${C} ul.nummerierung-hyphen li {
      display: table;
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
    }
    ${C} ul.nummerierung-hyphen li:before {
      display: table-cell;
      content: "\\002d";
      padding-right: 0.6em;
      word-break: keep-all;
    }
    ${C} ul.liste.nummerierung-disc li {
      list-style-type: disc;
    }
    ${C} ol.liste {
      counter-reset: item;
    }
    ${C} ol.liste li, ${C} ul.nummerierung-none.liste li {
      list-style: none;
      display: table;
      counter-increment: item;
      margin-left: 0;
      padding-left: 0;
    }
    ${C} ol.liste > li:before, ${C} ul.nummerierung-none.liste > li .listenel_num {
      display: table-cell;
      padding-right: 0.6em;
      word-break: keep-all;
    }
    ${C} ol.liste.nummerierung-decimal > li:before {
      content: counters(item, ".") ". ";
    }
    ${C} ol.liste.nummerierung-lower-alpha > li:before {
      content: "(" counter(item, lower-alpha) ") ";
    }
    ${C} .litera ul.nummerierung-none.liste > li:before {
      content: counter(item, lower-alpha) ") ";
    }
    ${C} ol.liste.nummerierung-lower-roman > li:before {
      content: counter(item, lower-roman) ". ";
    }
    ${C} ol.liste.nummerierung-upper-alpha > li:before {
      content: "(" counter(item, upper-alpha) ") ";
    }
    ${C} ol.liste.nummerierung-upper-roman > li:before {
      content: counter(item, upper-roman) ". ";
    }
  `
  renderer.renderStatic(styles)
}

const applyTextStyles = (renderer, theme) => {
  const styles = `
    ${C} p {
      margin-top: ${theme.spacing.space2};
    }
    ${C} .fett {
      font-weight: bold;
    }
    ${C} .unter {
      text-decoration: underline;
    }
    ${C} .kursiv {
      font-style: italic;
    }
    ${C} .fussnote {
      display: inline-block !important;
      position: relative !important;
      top: -.4em !important;
      text-align: center !important;
      line-height: 180% !important;
      color: ${theme.colors.bgFussnote} !important;
      font-weight: 700 !important;
      cursor: pointer !important;
    }
    ${C} blockquote {
      font-style: italic;
      background-color: #ffd1d1;
      padding: ${theme.spacing.space2};
      margin: ${theme.spacing.space3} 0;
    }
    ${C} q {
      font-style: italic;
      background-color: #EEEEEE;
    }
    ${C} .autor {
      font-style: italic;
      background-color: #EEEEEE;
    }
    ${C} .subtext {
      font-style: italic;
      background-color: #EEEEEE;
    }
    ${C} .kommentar {
      font-style: italic;
      background-color: #EEEEEE;
    }
    ${C} .marg {
      font-style: italic;
      background-color: #EEEEEE;
    }
    ${C} .randziffer {
      font-style: italic;
      background-color: #EEEEEE;
    }
    ${C} .textinhaltmetadaten {
      display: none;
    }
  `
  renderer.renderStatic(styles)
}

const applyEditorTextStyles = (renderer, theme) => {
  const styles = `
    ${C} .ktanf, ${C} .ktende, ${C} .note {
      color: ${theme.colors.textHighlight};
      font-weight: bold;
    }
    ${C} .ktanf .quelle, ${C} .note > :first-child {
      display: block;
    }
    ${C} .ktanf > *, ${C} .note > * {
      font-weight: normal;
    }
  `
  renderer.renderStatic(styles)
}

const applyTeasertextStyles = (renderer, theme) => {
  const styles = `
    ${C} .Teasertext {
      background-color: #EEEEEE;
      padding: ${theme.spacing.space2};
      margin: ${theme.spacing.space3} 0;
    }
  `
  renderer.renderStatic(styles)
}

const applyTableRules = (renderer, theme) => {
  const styles = `
    ${C} table {
      margin: ${theme.spacing.space3} 0;
      overflow-x: auto;
      border-width: 0;
    }
    ${C} table td, ${C} table th {
      padding: ${theme.spacing.space2};
    }
    ${C} table th[align=center] {
      text-align: center;
    }
    ${C} table th[align=right] {
      text-align: right;
    }
  `
  renderer.renderStatic(styles)
}

const applyHomepageRules = (renderer, theme) => {
  const styles = `
    ${C_HP} {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
    }
    ${C_HP} .Infotext {
      background-color: #f2f2f2;
      margin-bottom: ${theme.spacing.space4};
      padding: ${theme.spacing.space3};
      width: 100%;
      max-width: 500px;
    }
    ${C_HP}.folder .Infotext .titelbereich.h1 h1 {
      font-size: ${theme.fontSizes.text};
      font-weight: bold;
    }
    ${C_HP} img {
      margin: auto;
      max-width: 80%;
      width: auto;
      height: auto;
      padding: ${theme.spacing.space2} 0 0 0;
    }
  `
  renderer.renderStatic(styles)
}

const applyKvRules = (renderer, theme) => {
  const styles = `
    ${C} span[stil=zentriert], ${C} .zentriert {
      display: block;
      text-align: center;
    }
  `
  // Does not work because sometimes there are header areas
  // e.g. http://localhost:3408/kv-system/kollektivvertrag/SI-2073_de,4189188_20161101/Bergbau/Rahmen?p=pr-41891880000001_7641018
  /*
    ${C} .para.d-1:not(:first-child) {
      margin-top: calc(${theme.spacing.space4} * 2);
    }
   */
  renderer.renderStatic(styles)

  const debugStyles = `
      ${C_DEBUG} .d-1, 
      ${C_DEBUG} .d-2, 
      ${C_DEBUG} .d-3 {
        padding-left: ${theme.spacing.space2};
        border-left: 5px solid ${theme.colors.bgScrollbarTrack};
      }
      ${C_DEBUG} .normgrp.level-1, 
      ${C_DEBUG} .normgrp.level-2, 
      ${C_DEBUG} .normgrp.level-3 {
        padding-left: ${theme.spacing.space2};
        border-left: 5px solid ${debugColors.normGrp};
      }
      ${C_DEBUG} .textgr {
        padding-left: ${theme.spacing.space2};
        border-left: 5px solid ${debugColors.normel};
      }
      ${C_DEBUG} .textgr.level-1, 
      ${C_DEBUG} .textgr.level-2, 
      ${C_DEBUG} .textgr.level-3 {
        padding-left: ${theme.spacing.space2};
        border-left: 5px solid ${debugColors.textgr};
      }
      ${C_DEBUG} .normel {
        border-left: 5px solid ${debugColors.normel};
        padding-left: ${theme.spacing.space2};
      }      
    `
  renderer.renderStatic(debugStyles)
}

const applyDrdaRules = (renderer) => {
  const styles = `
    ${C} {
      line-height: 1.3;
    }
  `
  renderer.renderStatic(styles)
}

export const renderDigitalCSS = (renderer, theme) => {
  applyBaseRules(renderer, theme)
  applyBasePrintRules(renderer)
  applyLinkRules(renderer)
  applyTitelbereichRules(renderer, theme)
  applyNormelStyles(renderer, theme)
  applyListeStyles(renderer, theme)
  applyTextGrpStyles(renderer, theme)
  applyTextStyles(renderer, theme)
  applyEditorTextStyles(renderer, theme)
  applyTeasertextStyles(renderer, theme)
  applyTableRules(renderer, theme)

  applyHomepageRules(renderer, theme)
  applyKvRules(renderer, theme)

  applyDrdaRules(renderer)
}
