// @flow
import * as React from 'react'
import {ENTRY_POINTS} from '../app/oegbvConstants'

type Props = {
  env: string,
}

const createQuery = (entryPoint, search) => {
  if (search) {
    return `${search}&ep=${entryPoint}`
  }
  return `?ep=${entryPoint}`
}

const createLink = (entryPoint, text: string) => {
  const {pathname, hash, search} = window.location
  const newQuery = createQuery(entryPoint, search)
  return (
    <li>
      <a target="_blank" rel="noopener noreferrer" href={`${pathname}${newQuery}${hash}`}>
        {text}
      </a>
    </li>
  )
}

class DevTestFallbackComponent extends React.Component<Props> {
  render() {
    const {env} = this.props
    return (
      <div>
        <h1>Content-Pools {env}</h1>
        <p>You have not selected an entry-point. Select one to continue:</p>
        <ul style={{marginTop: '10px'}}>{createLink(ENTRY_POINTS.SOZIALLEISTUNGEN, 'Sozialleistungen')}</ul>
        <p />
        <p>
          Note: The entry-point is stored in your browser&apos;s session storage. Closing your browser tab, will reset
          the stored entry-point.
        </p>
      </div>
    )
  }
}

export default DevTestFallbackComponent
