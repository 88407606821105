// @flow
import {Observable} from 'rxjs/Observable'
import {getClientInfoAsRequestParamString} from '../../commons/idqsa/idqsa.js'
import {getHttpHeader} from '../../commons/utils/getHttpHeader.js'
import sozConstants from '../app/sozConstants.js'
import type {SozBookStructure} from '../types/SozBookStructure'
import type {SpidMagazine} from '../types/SpidMagazine.js'
import type {SpidMagazines} from '../types/SpidMagazines.js'

export const fetchBookStructure = (ajax: any): Observable<SozBookStructure> => {
  return ajax.getJSON('/api/v1/sozialleistungen/book-structure', getHttpHeader()).map((data) => data)
}

export const fetchBookPara = (paraId: string, ajax: any) => {
  return ajax.getJSON(`/api/v1/sozialleistungen/book-chapters/${paraId}`, getHttpHeader()).map((data) => data)
}

export const fetchAutoSuggest = (query: string, ajax: any) => {
  let httpQueryString = `/api/v1/sozialleistungen/auto-suggest?query=${encodeURI(query)}&page=0&page-size=25`
  httpQueryString += getClientInfoAsRequestParamString()
  return ajax.getJSON(httpQueryString, getHttpHeader()).map((data) => data)
}

export const fetchSearch = (query: string, ajax: any) => {
  let httpQueryString = `/api/v1/sozialleistungen/search?query=${encodeURI(query)}&page=0&page-size=25`
  httpQueryString += getClientInfoAsRequestParamString()
  return ajax.getJSON(httpQueryString, getHttpHeader()).map((data) => data)
}

export const fetchTocInfo = (ajax: any) => {
  return ajax.getJSON('/api/v1/sozialleistungen/toc-info', getHttpHeader()).map((data) => data)
}

export const fetchWebcDocument = (id: string, ajax: any) => {
  return ajax.getJSON(`/api/v1/sozialleistungen/documents/${id}`, getHttpHeader()).map((data) => data)
}

export const fetchSpidMagazines = (ajax: any): Observable<SpidMagazines> => {
  return ajax.getJSON('/api/v1/spid/magazines?year-from&year-to&start&count=1000', getHttpHeader()).map((data) => data)
}

export const fetchSpidMagazine = (id: string, ajax: any): Observable<SpidMagazine> => {
  return ajax.getJSON(`/api/v1/spid/magazines/${id}`, getHttpHeader()).map((data) => data)
}

export const fetchSpidAbout = (ajax: any) => {
  return fetchWebcDocument(sozConstants.mappings.spidAbout, ajax)
}
