import presetWeb from 'fela-preset-web'
import namedKeys from 'fela-plugin-named-keys'
import monolithic from 'fela-monolithic'

import '../../commons/fonts/fonts.css'

const init = (renderer) => {
  renderer.renderStatic('html, body, #app {height: 100%}')
  renderer.renderStatic('body {overflow-y: scroll}')
  renderer.renderStatic('em {font-weight: 700}')
}

const namedKeysPlugin = namedKeys({
  desktop: '@media (min-width: 1024px)',
  tablet: '@media (min-width: 768px)',
  mobile: '@media (max-width: 768px)',
})

const config = {
  plugins: [
    ...presetWeb,
    namedKeysPlugin,
  ],
}

if (process.env.NODE_ENV !== 'production') {
  config.enhancers = [monolithic()]
}

export default {
  init,
  config,
}
