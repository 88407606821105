import createIndoqaStore from './main/commons/app/createIndoqaStore'

const createStore = (history) => {
  const indoqaStore = createIndoqaStore({
    rootReducer: require('./main/soz/app/sozRootReducer.js').default,
    rootEpic: require('./main/soz/app/sozRootEpic.js').default,
    enableLogging: process.env.NODE_ENV !== 'production',
    history,
  })

  if (module.hot) {
    module.hot.accept('./main/soz/app/sozRootReducer', () => {
      const nextRootReducer = require('./main/soz/app/sozRootReducer.js').default
      indoqaStore.reduxStore.replaceReducer(nextRootReducer)
    })

    if (indoqaStore.epicMiddleware) {
      module.hot.accept('./main/soz/app/sozRootEpic', () => {
        const nextRootEpic = require('./main/soz/app/sozRootEpic.js').default
        indoqaStore.epicMiddleware.replaceEpic(nextRootEpic)
      })
    }
  }

  return indoqaStore.reduxStore
}

export default createStore
