// @flow
import type {DrdaState} from '../types/DrdaState'
import type {Action} from '../types/DrdaActions'

const initialState: DrdaState = {
  issues: [],
  selectedIssue: null,
  selectedMagazine: null,
  currentIssue: null,

  article: null,
  articleHtml: null,
  articleId: null,
  articlePage: 1,

  subscriptionIsPending: false,
  subscriptionSucceeded: false,
  subscriptionIsAbo: false,
  subscriptionError: null,

  searchQuery: null,
  searchIsLoading: false,
  searchResult: null,
  searchError: null,
  searchPageSize: 20,

  teaser: {
    id: '',
    title: '',
  },

  toc: null,
  contentNode: null,
}


const drda = (state: DrdaState = initialState, action: Action) => {
  switch (action.type) {
    case 'DRDA_LOAD_ISSUES': {
      return {
        ...state,
        issues: [],
      }
    }

    case 'DRDA_LOAD_ISSUES_SUCCESS': {
      return {
        ...state,
        issues: action.issues,
      }
    }

    case 'DRDA_LOAD_ISSUE': {
      return {
        ...state,
        selectedIssue: null,
      }
    }

    case 'DRDA_LOAD_ISSUE_SUCCESS': {
      return {
        ...state,
        selectedIssue: action.issue,
      }
    }

    case 'DRDA_SET_MAGAZINE': {
      return {
        ...state,
        articleId: null,
        selectedMagazine: action.magazine,
      }
    }

    case 'DRDA_SET_ARTICLE_ID': {
      return {
        ...state,
        article: null,
        articleHtml: null,
        articlePage: 1,
        articleId: action.id,
      }
    }

    case 'DRDA_SET_ARTICLE_HTML': {
      return {
        ...state,
        articleHtml: action.html,
      }
    }

    case 'DRDA_SET_ARTICLE_PAGE': {
      return {
        ...state,
        articlePage: action.page,
      }
    }

    case 'DRDA_SET_ARTICLE': {
      return {
        ...state,
        article: action.article,
      }
    }

    case 'DRDA_LOAD_CURRENT_ISSUE_SUCCESS': {
      return {
        ...state,
        currentIssue: action.issue,
      }
    }

    case 'DRDA_LOAD_CURRENT_ISSUE_PREVIEWS_SUCCESS': {
      return {
        ...state,
        currentIssue: {
          ...state.currentIssue,
          ...action.issue,
        },
      }
    }

    case 'DRDA_SUBSCRIBE': {
      return {
        ...state,
        subscriptionIsPending: true,
        subscriptionSucceeded: false,
        subscriptionIsAbo: action.isAbo,
        subscriptionError: null,
      }
    }

    case 'DRDA_SUBSCRIBE_SUCCESS': {
      return {
        ...state,
        subscriptionIsPending: false,
        subscriptionError: null,
        subscriptionSucceeded: true,
      }
    }

    case 'DRDA_SUBSCRIBE_ERROR': {
      return {
        ...state,
        subscriptionIsPending: false,
        subscriptionError: action.error,
      }
    }

    case 'DRDA_SEARCH': {
      return {
        ...state,
        searchQuery: action.query,
        searchIsLoading: true,
        searchResult: null,
        searchError: null,
        searchPage: 0,
      }
    }

    case 'DRDA_SEARCH_SUCCESS': {
      return {
        ...state,
        searchIsLoading: false,
        searchResult: action.result,
      }
    }

    case 'DRDA_SEARCH_ERROR': {
      return {
        ...state,
        searchIsLoading: false,
        searchError: action.error,
      }
    }

    case 'DRDA_LOAD_TEASER_SUCCESS': {
      return {
        ...state,
        teaser: {
          id: action.id,
          title: action.title,
        },
      }
    }

    case 'DRDA_LOAD_WEBC_CONTENT_SUCCESS': {
      return {
        ...state,
        contentNode: action.contentNode,
        toc: action.toc,
      }
    }

    default:
      return state
  }
}

export default drda
