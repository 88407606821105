// @flow
const PARAM_SET = 'set'

export const getCurrentSetFromSession = () => {
  const set = window.sessionStorage.getItem(PARAM_SET)
  if (!set) {
    return 'LIVE'
  }
  return set
}

export const saveCurrentSetToSession = (set: string) => {
  window.sessionStorage.setItem(PARAM_SET, set)
}
