// @flow
import {Observable} from 'rxjs/Observable'
import {getClientInfoAsRequestParamString} from '../../commons/idqsa/idqsa.js'
import {getHttpHeader} from '../../commons/utils/getHttpHeader.js'
import type {DrdaMagazine} from '../types/DrdaMagazine'
import type {DrdaArticle} from '../types/DrdaArticle'
import type {DrdaIssue} from '../types/DrdaIssue'
import type {DrdaSubscription} from '../types/DrdaSubscription'

export const getIssues = (ajax: any, yearFrom: number, yearTo: number): Observable<DrdaMagazine[]> => {
  return ajax
    .getJSON(`/api/v1/drda/issue?year-from=${yearFrom}&year-to=${yearTo}&count=500`, getHttpHeader())
    .map((data) => data)
}

export const getIssue = (ajax: any, id: string): Observable<DrdaMagazine[]> => {
  return ajax.getJSON(`/api/v1/drda/issue/${id}`, getHttpHeader()).map((data) => data)
}

export const getIssuePreview = (ajax: any, id: string): Observable<DrdaMagazine[]> => {
  return ajax.getJSON(`/api/v1/drda/issue/${id}/preview`, getHttpHeader()).map((data) => data)
}

export const getMagazine = (ajax: any, id: string): Observable<DrdaMagazine> => {
  return ajax.getJSON(`/api/v1/drda/magazine/${id}`, getHttpHeader()).map((data) => data)
}

export const getArticleLookup = (
  ajax: any,
  type: string,
  year: ?string,
  article: ?string,
  page: ?string
): Observable<DrdaMagazine> => {
  const linkParam = (name: string, value: ?string) => {
    if (!value) {
      return ''
    }

    return `${name}=${value}&`
  }

  const url =
    '/api/v1/drda/article-lookup?' +
    `${linkParam('type', type)}` +
    `${linkParam('year', year)}` +
    `${linkParam('article', article)}` +
    `${linkParam('page', page)}`

  return ajax.getJSON(url).map((data) => data)
}

export const getCurrentIssue = (ajax: any): Observable<DrdaIssue> => {
  return ajax.getJSON('/api/v1/drda/issue?count=1&sort=reverse', getHttpHeader()).map((data) => data)
}

export const getArticle = (ajax: any, id: string): Observable<DrdaArticle> => {
  return ajax.getJSON(`/api/v1/drda/article/${id}/info`, getHttpHeader()).map((response) => response)
}

export const getArticleHtml = (ajax: any, id: string): Observable<String> => {
  return ajax.getJSON(`/api/v1/drda/article/${id}/content`, getHttpHeader()).map((response) => response)
}

export const subscribe = (ajax: any, subscription: DrdaSubscription): Observable<String> => {
  return ajax
    .post('/api/v1/drda/subscribe', JSON.stringify(subscription), {'Content-Type': 'application/json'})
    .map((response) => response)
}

export const loadSearch = (ajax: any, query: string, page: number, pageSize: number): Observable<String> => {
  let httpQueryString = `/api/v1/drda/search?query=${encodeURI(query)}&page=${page}&page-size=${pageSize}`
  httpQueryString += getClientInfoAsRequestParamString()
  const headers = getHttpHeader()
  return ajax.getJSON(httpQueryString, headers).map((data) => data)
}

export const getArticlePdfUrl = (id: string) => {
  return `/api/v1/drda/article/${id}/pdf`
}

export const getArticlePdfAcceptHeader = () => {
  return 'application/pdf'
}

export const loadWebcDocument$ = (ajax: any, documentId: string) => {
  return ajax.getJSON(`/api/v1/webc/documents/${documentId}`, getHttpHeader())
}

export const loadTableOfContentService$ = (ajax: any, toc: any) => {
  if (toc) {
    return Observable.of(toc)
  }
  return ajax.getJSON('/api/v1/webc/toc-info/drda.at', getHttpHeader())
}
