import {forkJoin} from 'rxjs/observable/forkJoin'
import {of} from 'rxjs/observable/of'
import {cleanUrlPathPart} from '../../commons/utils/urlUtils.js'
import {
  loadArticleLookupError,
  loadArticleLookupSuccess,
  loadCurrentIssueError,
  loadCurrentIssuePreviewsError,
  loadCurrentIssuePreviewsSuccess,
  loadCurrentIssueSuccess,
  loadIssueError,
  loadIssuesError,
  loadIssuesSuccess,
  loadIssueSuccess,
  loadMagazineError,
  searchSuccess,
  searchError,
  setArticle,
  setArticleError,
  setArticleHtml,
  setArticleHtmlError,
  setMagazine,
  subscribeError,
  subscribeSuccess,
  loadWebcContentError,
  loadWebcContentSuccess,
  loadTeaserSuccess,
  loadTeaserError,
} from './drda.actions.js'

import {
  getArticle,
  getArticleHtml,
  getArticleLookup,
  getCurrentIssue,
  getIssue,
  getIssuePreview,
  getIssues,
  getMagazine,
  loadSearch,
  subscribe,
  loadTableOfContentService$,
  loadWebcDocument$,
} from './drda.service'

import {selectCurrentIssue, selectSearchPageSize, selectToc} from './drda.selectors.js'
import history from '../../../history.js'
import drdaConstants from '../app/drdaConstants.js'

const loadIssues$ = (action$, store, {ajax}) =>
  action$.ofType('DRDA_LOAD_ISSUES').switchMap((action) => {
    return getIssues(ajax, action.yearFrom, action.yearTo)
      .map((data) => loadIssuesSuccess(data.issues))
      .catch((error) => of(loadIssuesError(error)))
  })

const loadIssue$ = (action$, store, {ajax}) =>
  action$.ofType('DRDA_LOAD_ISSUE').switchMap((action) => {
    return forkJoin(getIssue(ajax, action.id), getIssuePreview(ajax, action.id))
      .map(([data, preview]) => loadIssueSuccess({...data, ...preview}))
      .catch((error) => of(loadIssueError(error)))
  })

const loadMagazine$ = (action$, store, {ajax}) =>
  action$.ofType('DRDA_LOAD_MAGAZINE').mergeMap((action) => {
    return getMagazine(ajax, action.id)
      .map((data) => setMagazine(data))
      .catch((error) => of(loadMagazineError(error)))
  })

const loadArticleLookup$ = (action$, store, {ajax}) =>
  action$.ofType('DRDA_LOAD_ARTICLE_LOOKUP').switchMap((action) => {
    return getArticleLookup(ajax, action.linkType, action.year, action.article, action.page)
      .map((data) => loadArticleLookupSuccess(data))
      .catch((error) => of(loadArticleLookupError(error)))
  })

const loadArticleLookupSuccess$ = (action$) =>
  action$
    .ofType('DRDA_LOAD_ARTICLE_LOOKUP_SUCCESS')
    .do((action) => history.replace(`${drdaConstants.paths.article}/${action.identifier.id}/${action.identifier.id}`))
    .ignoreElements()

const loadArticleLookupError$ = (action$) =>
  action$
    .ofType('DRDA_LOAD_ARTICLE_LOOKUP_ERROR')
    .do(() => history.replace(drdaConstants.paths.notFound))
    .ignoreElements()

const loadArticle$ = (action$, store, {ajax}) =>
  action$.ofType('DRDA_SET_ARTICLE_ID').switchMap((action) => {
    return getArticle(ajax, action.id, action.name)
      .map((data) => setArticle(data))
      .catch((error) => of(setArticleError(error)))
  })

const loadArticleHtml$ = (action$, store, {ajax}) =>
  action$
    .ofType('DRDA_SET_ARTICLE')
    .filter((action) => action.article.availableAsHtml)
    .switchMap((action) => {
      return getArticleHtml(ajax, action.article.id)
        .map((data) => setArticleHtml(data))
        .catch((error) => of(setArticleHtmlError(error)))
    })

const loadCurrentIssue$ = (action$, store, {ajax}) =>
  action$.ofType('DRDA_LOAD_CURRENT_ISSUE').switchMap(() => {
    return getCurrentIssue(ajax)
      .map((data) => loadCurrentIssueSuccess(data.issues[0]))
      .catch((error) => of(loadCurrentIssueError(error)))
  })

const loadCurrentIssuePreviews$ = (action$, store, {ajax}) =>
  action$.ofType('DRDA_LOAD_CURRENT_ISSUE_SUCCESS').switchMap(() => {
    return getIssuePreview(ajax, selectCurrentIssue(store.getState()).id)
      .map((data) => loadCurrentIssuePreviewsSuccess(data))
      .catch((error) => of(loadCurrentIssuePreviewsError(error)))
  })

const search$ = (action$, store, {ajax}) =>
  action$.ofType('DRDA_SEARCH').switchMap((action) => {
    return loadSearch(ajax, action.query, action.page, selectSearchPageSize(store.getState()))
      .map((data) => searchSuccess(data))
      .catch((error) => of(searchError(error)))
  })

const subscribe$ = (action$, store, {ajax}) =>
  action$.ofType('DRDA_SUBSCRIBE').switchMap((action) => {
    return subscribe(ajax, action.subscription)
      .map(() => subscribeSuccess())
      .catch((error) => of(subscribeError(error)))
  })

const loadWebCDocument$ = (action$, store, {ajax}) =>
  action$
    .ofType('DRDA_LOAD_WEBC_CONTENT')
    .switchMap((action) => {
      const existingToc = selectToc(store.getState())
      return loadTableOfContentService$(ajax, existingToc)
        .switchMap((toc) => {
          return loadWebcDocument$(ajax, action.documentId)
            .map((contentNode) => {
              history.replace(`/c/${contentNode.id}/${cleanUrlPathPart(contentNode.title)}`)
              return loadWebcContentSuccess(toc, contentNode)
            })
        })
        .catch((error) => of(loadWebcContentError(error)))
    })

const loadTeaser$ = (action$, store, {ajax}) =>
  action$
    .ofType('DRDA_LOAD_TEASER')
    .switchMap(() => {
      const existingToc = selectToc(store.getState())
      return loadTableOfContentService$(ajax, existingToc)
        .switchMap((toc) => {
          const documentId = toc.mappings['Teaser']
          if (!documentId) {
            throw Error('There is no teaser mapping')
          }
          return loadWebcDocument$(ajax, documentId)
            .map((contentNode) => {
              const {id} = contentNode
              const titel = contentNode.title || ''
              return loadTeaserSuccess(id, titel)
            })
        })
        .catch((error) => of(loadTeaserError(error)))
    })

export default [
  loadIssues$,
  loadIssue$,
  loadMagazine$,
  loadArticle$,
  loadArticleHtml$,
  loadCurrentIssue$,
  loadCurrentIssuePreviews$,
  subscribe$,
  loadArticleLookup$,
  loadArticleLookupSuccess$,
  loadArticleLookupError$,
  search$,
  loadWebCDocument$,
  loadTeaser$,
]
