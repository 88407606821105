const getInitialState = () => {
  const initialState = window['__INITIAL_STATE__']
  if (initialState) {
    return initialState
  }
  // provide initial state for development (this code should be eliminated by webpack)
  if (process.env.NODE_ENV !== 'production') {
    return {
      conf: {
        entryPoint: null,
        auth: {
          url: 'https://sso-integration.apps.ocp.3sit.at/auth/',
          realm: 'oegb',
          clientId: 'content-pools',
          credentials: {
            secret: '31dd4d70-097b-45ae-8732-0a06a23420c5',
          },
        },
      },
      links: {
        drda: {
          reqParam: 'ep=drda',
          base: '/',
        },
        soz: {
          reqParam: 'ep=soz',
          base: '/',
        },
      },
      it: true,
      alphaFeatures: 'ALL',
      env: 'localhost',
    }
  }
  // this error should never be thrown if there is a INITIAL_STATE available
  throw Error('The initial state is not set correctly')
}

export default getInitialState
