// @flow
import * as React from 'react'

class ProdFallbackComponent extends React.Component<{}> {

  render() {
    return (
      <div>
        <h1>Error</h1>
        <p>There is no entry-point set or the entry-point is not available.</p>
      </div>
    )
  }
}

export default ProdFallbackComponent
