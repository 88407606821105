// @flow
import type {FootnoteState} from '../../commons/components/footnote/types/FootnoteState'
import type {SozState} from '../types/SozState'

type State = {
  footnote: FootnoteState,
  soz: SozState,
}

export const selectSozState = (state: State) => state.soz
export const selectFootnoteState = (state: State) => state.footnote
