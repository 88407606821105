// @flow
import {createSelector} from 'reselect'
import type {SozState} from '../types/SozState'
import {selectSozState} from '../app/sozSelectors'
import type {SozBookStructure} from '../types/SozBookStructure'
import type {WebcTocInfo} from '../../commons/types/WebcTocInfo'

export const selectBookStructure = createSelector(selectSozState, (state: SozState) => state.bookStructure)
export const selectIsBookStructureLoading = createSelector(selectSozState, (state: SozState) => state.bookStructureIsLoading)
export const selectBookStructureError = createSelector(selectSozState, (state: SozState) => state.bookStructureError)

export const selectBookPara = createSelector(selectSozState, (state: SozState) => state.bookPara)
export const selectIsBookParaLoading = createSelector(selectSozState, (state: SozState) => state.bookParaIsLoading)
export const selectSelectedChapterId = createSelector(selectSozState, (state: SozState) => state.selectedChapterId)

export const selectAutoSuggestResult = createSelector(selectSozState, (state: SozState) => state.autoSuggestResult)

export const selectAutoSuggestDocuments = createSelector(selectAutoSuggestResult, (result) => {
  return result ? result.documents : []
})

export const selectSearchResult = createSelector(selectSozState, (state) => state.searchResult)

export const selectSearchIsLoading = createSelector(selectSozState, (state) => state.searchIsLoading)

export const selectSearchQuery = createSelector(selectSozState, (state) => state.searchQuery)

const findPara = (structure: SozBookStructure, paraId: string): ?SozBookStructure => {
  if (!structure || !structure.children) {
    return null
  }
  for (let i = 0; i < structure.children.length; i += 1) {
    const child = structure.children[i]
    if (child.link.id === paraId) {
      return child
    }
    const ch = findPara(child, paraId)
    if (ch) {
      return ch
    }
  }
  return null
}

export const selectChildrenLinksIfNoContent = createSelector(selectBookStructure, selectBookPara, (structure, para) => {
  if (!para || para.children) {
    // Para does not exist or has content
    return null
  }
  const c = findPara(structure, para.id)
  if (c && c.noContent) {
    return c
  }
  return null
})

export const selectTocInfo = createSelector(selectSozState, (state) => state.tocInfo)

export const selectTocRootDocument = createSelector(selectTocInfo, (tocInfo: WebcTocInfo) =>
  (tocInfo ? tocInfo.rootDocumentId : null)
)

export const selectTocMappings = createSelector(selectTocInfo, (tocInfo: WebcTocInfo) =>
  (tocInfo ? tocInfo.mappings : null))

export const selectWebcDocument = createSelector(selectSozState, (state) => state.webcDocument)

const findParaIdInChapter = (chapterStructure: SozBookStructure, paraId: string) => {
  if (!chapterStructure || !chapterStructure.children) {
    return false
  }
  for (let i = 0; i < chapterStructure.children.length; i += 1) {
    const child = chapterStructure.children[i]
    if (child.link.id === paraId) {
      return true
    }
    if (findParaIdInChapter(child, paraId)) {
      return true
    }
  }
  return false
}

export const findChapter = (bookStructure: SozBookStructure, paraId: string) => {
  if (!bookStructure || !bookStructure.children) {
    return null
  }
  for (let i = 0; i < bookStructure.children.length; i += 1) {
    const chapter = bookStructure.children[i]
    const eachChapterId = chapter.link.id
    if (eachChapterId === paraId) {
      return paraId
    }
    const containsParaId = findParaIdInChapter(chapter, paraId)
    if (containsParaId) {
      return eachChapterId
    }
  }
  return null
}
