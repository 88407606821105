// polyfill imports
import 'react-app-polyfill/ie11'
import 'polyfill-array-includes'
import 'element-scroll-polyfill'

// local polyfills
import './main/commons/polyfills/cryptoPolyfill'
import './main/commons/polyfills/stringStartsWithPolyfill'
import './main/commons/polyfills/nodeListForEachPolyfill'
import './main/commons/polyfills/arrayIncludesPolyfill'
import './main/commons/polyfills/arrayFindPolyfill'
import './main/commons/polyfills/objectEntriesPolyfill'
import './main/commons/polyfills/mathImulPolyfill'
import './main/commons/polyfills/stringsRepeatPolyfill'

// RxJS import
import 'rxjs'

// Application imports
import React from 'react'
import {render} from 'react-dom'
import PiwikReactRouter from 'piwik-react-router'
import {createRenderer} from 'indoqa-react-fela'
import loadable from '@loadable/component'

// local imports
import history from './history'
import IndoqaApplication from './main/commons/app/IndoqaApplication.react'
import {ENTRY_POINTS} from './main/commons/app/oegbvConstants'
import getEntryPoint from './main/commons/utils/getEntryPoint.js'
import fela from './main/commons/app/fela.js'

// import entry points
// Note: the createStore functions have to be located in the root src folder in order
// to get hot-reloading working)
// ------------------------------------------------------------------------------------------
import drdaCreateStore from './drdaCreateStore'
import {renderDigitalCSS} from './main/drda/components/DigitalCSSRules.js'
import sozCreateStore from './sozCreateStore'

import ProdFallbackComponent from './main/commons/fallback/ProdFallbackComponent.react'
import DevTestFallbackComponent from './main/commons/fallback/DevTestFallbackComponent.react'

const appElement = document.getElementById('app')
const DrdaApp = loadable(() => import('./main/drda/app/DrdaApp.react'))
const SozApp = loadable(() => import('./main/soz/app/SozApp.react'))

import drdaWebcTheme from './main/drda/app/drdaWebcTheme.js'

// render React application
// ------------------------------------------------------------------------------------------
const renderDRdA = () => {
  const drdaStore = drdaCreateStore(history)
  const piwik = PiwikReactRouter({
    url: 'matomo.gewerkschaften-online.at/',
    siteId: 11,
  })
  const renderer = createRenderer(fela)
  renderDigitalCSS(renderer, drdaWebcTheme)
  render(
    <IndoqaApplication store={drdaStore} renderer={renderer} history={piwik.connectToHistory(history)}>
      <DrdaApp />
    </IndoqaApplication>,
    appElement
  )
}

const renderSozialleistungen = () => {
  const sozStore = sozCreateStore(history)
  const piwik = PiwikReactRouter({
    url: 'matomo.gewerkschaften-online.at/',
    siteId: 69,
  })
  const renderer = createRenderer(fela)
  render(
    <IndoqaApplication store={sozStore} renderer={renderer} history={piwik.connectToHistory(history)}>
      <SozApp />
    </IndoqaApplication>,
    appElement
  )
  if (module.hot) {
    module.hot.accept('./main/soz/app/SozApp.react', () => {
      const NextApp = require('./main/soz/app/SozApp.react').default
      render(
        <IndoqaApplication history={history} store={sozStore} renderer={renderer}>
          <NextApp />
        </IndoqaApplication>,
        appElement
      )
    })
  }
}

const renderDevFallback = () => {
  render(<DevTestFallbackComponent env="Development" />, appElement)
}

const renderTestFallback = () => {
  render(<DevTestFallbackComponent env="Test" />, appElement)
}

const renderProdFallback = () => {
  render(<ProdFallbackComponent />, appElement)
}

switch (getEntryPoint()) {
  // entry points
  case ENTRY_POINTS.DRDA:
    renderDRdA()
    break
  case ENTRY_POINTS.SOZIALLEISTUNGEN:
    renderSozialleistungen()
    break

  // fallbacks
  case ENTRY_POINTS.FALLBACK_TEST:
    renderTestFallback()
    break
  case ENTRY_POINTS.FALLBACK_DEV:
    renderDevFallback()
    break
  default:
    renderProdFallback()
}
