// @flow
import type {SozAction} from '../types/SozActions'
import type {SozBookStructure} from '../types/SozBookStructure'
import type {SozBookPara} from '../types/SozBookPara'
import type {AutoSuggestResult} from '../../commons/types/AutoSuggestResult'
import type {SozSearchResult} from '../types/SozSearchResult'
import type {WebcTocInfo} from '../../commons/types/WebcTocInfo'
import type {ContentNode} from '../../commons/types/ContentNode'

// Book Structure

export const loadBookStructure = (): SozAction => ({
  type: 'SOZ_LOAD_BOOK_STRUCTURE',
})

export const loadBookStructureSuccess = (structure: SozBookStructure): SozAction => ({
  type: 'SOZ_LOAD_BOOK_STRUCTURE_SUCCESS',
  structure,
})

export const loadBookStructureError = (error: Error): SozAction => ({
  type: 'SOZ_LOAD_BOOK_STRUCTURE_ERROR',
  error,
})

// Book Para

export const loadBookPara = (id: string, title: string): SozAction => ({
  type: 'SOZ_LOAD_BOOK_PARA',
  id,
  title,
})

export const loadBookParaSuccess = (para: SozBookPara, selectedChapterId: string): SozAction => ({
  type: 'SOZ_LOAD_BOOK_PARA_SUCCESS',
  para,
  selectedChapterId,
})

export const loadBookParaError = (error: Error): SozAction => ({
  type: 'SOZ_LOAD_BOOK_PARA_ERROR',
  error,
})


// Autosuggest

export const loadAutoSuggest = (query: string): SozAction => ({
  type: 'SOZ_LOAD_AUTOSUGGEST',
  query,
})

export const loadAutoSuggestSuccess = (result: AutoSuggestResult): SozAction => ({
  type: 'SOZ_LOAD_AUTOSUGGEST_SUCCESS',
  result,
})

export const loadAutoSuggestError = (error: Error): SozAction => ({
  type: 'SOZ_LOAD_AUTOSUGGEST_ERROR',
  error,
})


// Search

export const search = (query: string): SozAction => ({
  type: 'SOZ_LOAD_SEARCH',
  query,
})

export const loadSearchSuccess = (result: SozSearchResult): SozAction => ({
  type: 'SOZ_LOAD_SEARCH_SUCCESS',
  result,
})

export const loadSearchError = (error: Error): SozAction => ({
  type: 'SOZ_LOAD_SEARCH_ERROR',
  error,
})


// TOC

export const loadTocInfo = (): SozAction => ({
  type: 'SOZ_LOAD_TOC_INFO',
})

export const loadTocInfoSuccess = (tocInfo: WebcTocInfo): SozAction => ({
  type: 'SOZ_LOAD_TOC_INFO_SUCCESS',
  tocInfo,
})

export const loadTocInfoError = (error: Error): SozAction => ({
  type: 'SOZ_LOAD_TOC_INFO_ERROR',
  error,
})


// WEBC

export const loadWebcDocument = (id: string, name: string): SozAction => ({
  type: 'SOZ_LOAD_WEBC_DOCUMENT',
  id,
  name,
})

export const loadWebcDocumentSuccess = (document: ContentNode): SozAction => ({
  type: 'SOZ_LOAD_WEBC_DOCUMENT_SUCCESS',
  document,
})

export const loadWebcDocumentError = (error: Error): SozAction => ({
  type: 'SOZ_LOAD_WEBC_DOCUMENT_ERROR',
  error,
})
