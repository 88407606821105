import React from 'react'
import PropTypes from 'prop-types'
import {Provider as Redux} from 'react-redux'
import {IndoqaFela} from 'indoqa-react-fela'
import {ConnectedRouter} from 'react-router-redux'

const IndoqaApplication = ({store, renderer, history, children}) => {
  return (
    <Redux store={store}>
      <IndoqaFela renderer={renderer}>
        <ConnectedRouter history={history}>
          {children}
        </ConnectedRouter>
      </IndoqaFela>
    </Redux>
  )
}

IndoqaApplication.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  renderer: PropTypes.object.isRequired,
}

export default IndoqaApplication
