import {Observable} from 'rxjs/Observable'
import {cleanUrlPathPart} from '../../commons/utils/urlUtils.js'
import sozConstants from '../app/sozConstants.js'
import {
  loadAutoSuggestError,
  loadAutoSuggestSuccess,
  loadBookParaError,
  loadBookParaSuccess,
  loadBookStructureError,
  loadBookStructureSuccess,
  loadSearchError,
  loadSearchSuccess,
  loadTocInfoError,
  loadTocInfoSuccess,
  loadWebcDocumentError,
  loadWebcDocumentSuccess,
} from './soz.actions'
import {findChapter, selectBookStructure, selectTocRootDocument} from './soz.selectors.js'
import {
  fetchAutoSuggest,
  fetchBookPara,
  fetchBookStructure,
  fetchSearch,
  fetchTocInfo,
  fetchWebcDocument,
} from './soz.service'

const loadBookStructure$ = (action$, store, {ajax}) =>
  action$.ofType('SOZ_LOAD_BOOK_STRUCTURE').switchMap(() =>
    fetchBookStructure(ajax)
      .map((result) => loadBookStructureSuccess(result))
      .catch((error) => Observable.of(loadBookStructureError(error)))
  )

const loadBookPara$ = (action$, store, {ajax, replace, notFoundRedirect}) =>
  action$.ofType('SOZ_LOAD_BOOK_PARA').switchMap((action) => {
    const bookStructure = selectBookStructure(store.getState())
    const selectedChapterId = findChapter(bookStructure, action.id)
    if (action.title === undefined) {
      return fetchBookPara(action.id, ajax)
        .switchMap((result) => {
          const cleanedTitle = cleanUrlPathPart(result.title)
          const hash = window.location.hash ? `${window.location.hash}` : ''
          return Observable.merge(
            Observable.of(replace(`${sozConstants.paths.book}/${action.id}/${cleanedTitle}${hash}`)),
            Observable.of(loadBookParaSuccess(result, selectedChapterId))
          )
        })
        .catch((error) => {
          if (error.status === 404) {
            notFoundRedirect()
          }
          return Observable.of(loadBookParaError(error))
        })
    }
    return fetchBookPara(action.id, ajax)
      .map((result) => {
        const cleanedTitle = cleanUrlPathPart(result.title)
        if (action.title && cleanedTitle !== action.title) {
          notFoundRedirect()
          return null
        }
        return loadBookParaSuccess(result, selectedChapterId)
      })
      .catch((error) => {
        if (error.status === 404) {
          notFoundRedirect()
        }
        return Observable.of(loadBookParaError(error))
      })
  })

const loadAutoSuggest$ = (action$, store, {ajax}) =>
  action$
    .ofType('SOZ_LOAD_AUTOSUGGEST')
    .filter((action) => action.query && action.query.length > 0)
    .debounceTime(290)
    .switchMap((action) =>
      fetchAutoSuggest(action.query, ajax)
        .map((result) => loadAutoSuggestSuccess(result))
        .catch((error) => Observable.of(loadAutoSuggestError(error)))
    )

const loadSearch$ = (action$, store, {ajax}) =>
  action$.ofType('SOZ_LOAD_SEARCH').switchMap((action) =>
    fetchSearch(action.query, ajax)
      .map((result) => loadSearchSuccess(result))
      .catch((error) => Observable.of(loadSearchError(error)))
  )

const loadTocInfo$ = (action$, store, {ajax}) =>
  action$.ofType('SOZ_LOAD_TOC_INFO').switchMap(() =>
    fetchTocInfo(ajax)
      .map((result) => loadTocInfoSuccess(result))
      .catch((error) => Observable.of(loadTocInfoError(error)))
  )

const loadWebcDocument$ = (action$, store, {ajax, replace, push}) => {
  return action$.ofType('SOZ_LOAD_WEBC_DOCUMENT').switchMap((action) => {
    if (!action.name) {
      return fetchWebcDocument(action.id, ajax)
        .switchMap((result) => {
          const rootDocId = selectTocRootDocument(store.getState())
          if (rootDocId === action.id) {
            return Observable.of(loadWebcDocumentSuccess(result))
          }
          const cleanedTitle = cleanUrlPathPart(result.title)
          return Observable.merge(
            Observable.of(replace(`${sozConstants.paths.webc}/${action.id}/${cleanedTitle}`)),
            Observable.of(loadWebcDocumentSuccess(result))
          )
        })
        .catch((error) => {
          if (error.status === 404) {
            return Observable.of(push(sozConstants.paths.notFound))
          }
          return Observable.of(loadWebcDocumentError(error))
        })
    }
    return fetchWebcDocument(action.id, ajax)
      .switchMap((result) => {
        if (cleanUrlPathPart(result.title) !== action.name) {
          return Observable.of(push(sozConstants.paths.notFound))
        }
        return Observable.of(loadWebcDocumentSuccess(result))
      })
      .catch((error) => Observable.of(loadWebcDocumentError(error)))
  })
}

export default [loadBookStructure$, loadBookPara$, loadAutoSuggest$, loadSearch$, loadTocInfo$, loadWebcDocument$]
