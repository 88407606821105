// @flow
import {combineReducers} from 'redux'

import drda from '../store/drda.reducer'

const reducers = {
  drda,
}

export default combineReducers(reducers)
