// @flow

import {createSelector} from 'reselect'

import type {DrdaState} from '../types/DrdaState'
import {selectDrdaState} from '../app/drdaSelectors'

export const selectIssues = createSelector(selectDrdaState, (state: DrdaState) => state.issues)
export const selectSelectedIssue = createSelector(selectDrdaState, (state: DrdaState) => state.selectedIssue)
export const selectCurrentIssue = createSelector(selectDrdaState, (state: DrdaState) => state.currentIssue)
export const selectSelectedMagazine = createSelector(selectDrdaState, (state: DrdaState) => state.selectedMagazine)

export const selectArticle = createSelector(selectDrdaState, (state: DrdaState) => state.article)
export const selectArticleHtml = createSelector(selectDrdaState, (state: DrdaState) => state.articleHtml)
export const selectArticleId = createSelector(selectDrdaState, (state: DrdaState) => state.articleId)
export const selectArticlePage = createSelector(selectDrdaState, (state: DrdaState) => state.articlePage)

export const selectSubscriptionIsPending = createSelector(selectDrdaState, (state: DrdaState) => state.subscriptionIsPending)
export const selectSubscriptionIsAbo = createSelector(selectDrdaState, (state: DrdaState) => state.subscriptionIsAbo)
export const selectSubscriptionSucceeded = createSelector(selectDrdaState, (state: DrdaState) => state.subscriptionSucceeded)
export const selectSubscriptionError = createSelector(selectDrdaState, (state: DrdaState) => state.subscriptionError)

export const selectSearchQuery = createSelector(selectDrdaState, (state: DrdaState) => state.searchQuery)
export const selectSearchIsLoading = createSelector(selectDrdaState, (state: DrdaState) => state.searchIsLoading)
export const selectSearchResult = createSelector(selectDrdaState, (state: DrdaState) => state.searchResult)
export const selectSearchError = createSelector(selectDrdaState, (state: DrdaState) => state.searchError)
export const selectSearchPageSize = createSelector(selectDrdaState, (state: DrdaState) => state.searchPageSize)
export const selectSearchPageCount = createSelector(selectSearchResult, selectSearchPageSize, (searchResult, pageSize) => {
  return searchResult ? Math.floor(searchResult.documentsFound / pageSize) : 0
})

export const selectTeaser = createSelector(selectDrdaState, (state: DrdaState) => state.teaser)

export const selectContentNode = createSelector(selectDrdaState, (state: DrdaState) => state.contentNode)
export const selectToc = createSelector(selectDrdaState, (state: DrdaState) => state.toc)
