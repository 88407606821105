// @flow
import type {FootnoteAction} from '../types/FootnoteActions.js'
import type {FootnoteState} from '../types/FootnoteState.js'

const initialState: FootnoteState = {
  contentHtml: '',
}

export default (state: FootnoteState = initialState, action: FootnoteAction) => {
  switch (action.type) {
    case 'FOOTNOTE_SHOW':
      return {
        contentHtml: action.contentHtml,
      }
    case '@@router/LOCATION_CHANGE':
    case 'FOOTNOTE_CLEAR':
      return {
        contentHtml: '',
      }
    default:
      return state
  }
}
