// @flow
import queryString from 'query-string'

import {ENTRY_POINTS} from '../app/oegbvConstants'
import getInitialState from './getInitialState'

const PARAM_EP = 'ep'

const isEntryPointExisting = (entryPoint) => {
  if (typeof entryPoint === 'undefined' || entryPoint == null) {
    return false
  }
  return Object.keys(ENTRY_POINTS).map((ep) => ENTRY_POINTS[ep]).includes(entryPoint)
}

const getEntryPointFromQueryString = () => {
  const queryParams = queryString.parse(window.location.search)
  const entryPoint = queryParams[PARAM_EP]
  if (typeof entryPoint !== 'undefined') {
    if (isEntryPointExisting(entryPoint)) {
      // eslint-disable-next-line
      console.log(`Selecting entry-point '${entryPoint}' from the query-string`)
      return entryPoint
    }
    // eslint-disable-next-line
    console.warn(`The entry-point '${entryPoint}' does not exist.`)
  }

  return null
}

const getEntryPointFromQueryStringOrSession = (fallbackEntryPoint: string) => {
  const sessionStorageEntryPoint = window.sessionStorage.getItem(PARAM_EP)
  const queryStringEntryPoint = getEntryPointFromQueryString()
  if (queryStringEntryPoint !== null) {
    window.sessionStorage.setItem(PARAM_EP, queryStringEntryPoint)
  } else if (sessionStorageEntryPoint === null) {
    // eslint-disable-next-line
    console.log(`Setting the fallback entry-point '${fallbackEntryPoint}'`)
    window.sessionStorage.setItem(PARAM_EP, fallbackEntryPoint)
  }

  const entryPoint = window.sessionStorage.getItem(PARAM_EP)
  // eslint-disable-next-line
  console.log(`Using the entry-point '${entryPoint}'`)
  return entryPoint
}

const getEntryPoint = () => {
  const initialState = getInitialState()
  if (!initialState) {
    return ENTRY_POINTS.FALLBACK_PROD
  }

  const {entryPoint} = initialState.conf
  if (initialState.it) {
    if (entryPoint === null || entryPoint === ENTRY_POINTS.FALLBACK_TEST) {
      return getEntryPointFromQueryStringOrSession(ENTRY_POINTS.FALLBACK_TEST)
    }
  }

  if (isEntryPointExisting(entryPoint)) {
    return entryPoint
  }
  return ENTRY_POINTS.FALLBACK_PROD
}

export default getEntryPoint
