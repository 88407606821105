// @flow
import type {Action} from '../types/DrdaActions'
import type {DrdaMagazine} from '../types/DrdaMagazine'
import type {DrdaArticle} from '../types/DrdaArticle'
import type {DrdaIssue} from '../types/DrdaIssue'
import type {DrdaSubscription} from '../types/DrdaSubscription'
import type {DrdaIdentifier} from '../types/DrdaIdentifier'
import type {SearchResult} from '../../commons/types/SearchResult'

export const loadIssues = (yearFrom: number, yearTo: number): Action => ({
  type: 'DRDA_LOAD_ISSUES',
  yearFrom,
  yearTo,
})

export const loadIssuesSuccess = (issues: Array<DrdaIssue>): Action => ({
  type: 'DRDA_LOAD_ISSUES_SUCCESS',
  issues,
})

export const loadIssuesError = (error: Error): Action => ({
  type: 'DRDA_LOAD_ISSUES_ERROR',
  error,
})

export const loadIssue = (id: string): Action => ({
  type: 'DRDA_LOAD_ISSUE',
  id,
})

export const loadIssueSuccess = (issue: DrdaIssue): Action => ({
  type: 'DRDA_LOAD_ISSUE_SUCCESS',
  issue,
})

export const loadIssueError = (error: Error): Action => ({
  type: 'DRDA_LOAD_ISSUE_ERROR',
  error,
})

export const loadCurrentIssue = (): Action => ({
  type: 'DRDA_LOAD_CURRENT_ISSUE',
})

export const loadCurrentIssueSuccess = (issue: DrdaIssue): Action => ({
  type: 'DRDA_LOAD_CURRENT_ISSUE_SUCCESS',
  issue,
})

export const loadCurrentIssueError = (error: Error): Action => ({
  type: 'DRDA_LOAD_CURRENT_ISSUE_ERROR',
  error,
})

export const loadCurrentIssuePreviews = (): Action => ({
  type: 'DRDA_LOAD_CURRENT_ISSUE_PREVIEWS',
})

export const loadCurrentIssuePreviewsSuccess = (issue: DrdaIssue): Action => ({
  type: 'DRDA_LOAD_CURRENT_ISSUE_PREVIEWS_SUCCESS',
  issue,
})

export const loadCurrentIssuePreviewsError = (error: Error): Action => ({
  type: 'DRDA_LOAD_CURRENT_ISSUE_PREVIEWS_ERROR',
  error,
})

export const loadArticleLookup = (type: string, year: ?string, article: ?string, page: ?string): Action => ({
  type: 'DRDA_LOAD_ARTICLE_LOOKUP',
  linkType: type,
  year,
  article,
  page,
})

export const loadArticleLookupSuccess = (identifier: DrdaIdentifier): Action => ({
  type: 'DRDA_LOAD_ARTICLE_LOOKUP_SUCCESS',
  identifier,
})

export const loadArticleLookupError = (error: Error): Action => ({
  type: 'DRDA_LOAD_ARTICLE_LOOKUP_ERROR',
  error,
})

export const loadMagazine = (id: string): Action => ({
  type: 'DRDA_LOAD_MAGAZINE',
  id,
})

export const loadMagazineError = (error: Error): Action => ({
  type: 'DRDA_LOAD_MAGAZINE_ERROR',
  error,
})

export const setMagazine = (magazine: DrdaMagazine): Action => ({
  type: 'DRDA_SET_MAGAZINE',
  magazine,
})

export const setMagazineError = (magazine: DrdaMagazine): Action => ({
  type: 'DRDA_SET_MAGAZINE',
  magazine,
})

export const setArticle = (article: DrdaArticle): Action => ({
  type: 'DRDA_SET_ARTICLE',
  article,
})

export const setArticleError = (error: Error): Action => ({
  type: 'DRDA_SET_ARTICLE_ERROR',
  error,
})

export const setArticleHtml = (html: string): Action => ({
  type: 'DRDA_SET_ARTICLE_HTML',
  html,
})

export const setArticleHtmlError = (error: Error): Action => ({
  type: 'DRDA_SET_ARTICLE_HTML_ERROR',
  error,
})

export const setArticleId = (id: string, name: string): Action => ({
  type: 'DRDA_SET_ARTICLE_ID',
  id,
  name,
})

export const setArticlePage = (page: number): Action => ({
  type: 'DRDA_SET_ARTICLE_PAGE',
  page,
})

export const subscribe = (subscription: DrdaSubscription, isAbo: boolean): Action => ({
  type: 'DRDA_SUBSCRIBE',
  isAbo,
  subscription,
})

export const subscribeSuccess = (): Action => ({
  type: 'DRDA_SUBSCRIBE_SUCCESS',
})

export const subscribeError = (error: Error): Action => ({
  type: 'DRDA_SUBSCRIBE_ERROR',
  error,
})

export const search = (query: string, page: ?number): Action => ({
  type: 'DRDA_SEARCH',
  query,
  page: page || 0,
})

export const searchSuccess = (result: SearchResult): Action => ({
  type: 'DRDA_SEARCH_SUCCESS',
  result,
})

export const searchError = (error: Error): Action => ({
  type: 'DRDA_SEARCH_ERROR',
  error,
})

export const loadWebcContent = (documentId: string): Action => ({
  type: 'DRDA_LOAD_WEBC_CONTENT',
  documentId,
})

export const loadWebcContentSuccess = (toc: any, contentNode: any): Action => ({
  type: 'DRDA_LOAD_WEBC_CONTENT_SUCCESS',
  toc,
  contentNode,
})

export const loadWebcContentError = (error: Error): Action => ({
  type: 'DRDA_LOAD_WEBC_CONTENT_ERROR',
  error,
})

export const loadTeaser = (): Action => ({
  type: 'DRDA_LOAD_TEASER',
})

export const loadTeaserSuccess = (id: string, title: string): Action => ({
  type: 'DRDA_LOAD_TEASER_SUCCESS',
  title,
  id,
})

export const loadTeaserError = (error: Error): Action => ({
  type: 'DRDA_LOAD_TEASER_ERROR',
  error,
})
