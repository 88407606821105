// @flow
import {getCurrentSetFromSession} from './setUtils.js'

type CPHeaders = {
  set: string,
  'search-mode'?: string,
  Authorization?: string,
}

export const getHttpHeader = () => {
  const headers: CPHeaders = {
    set: getCurrentSetFromSession(),
  }
  return headers
}
