// @flow
const ENTRY_POINTS = {
  AR: 'ar',
  DIGITAL: 'digital',
  DRDA: 'drda',
  KVSYSTEM: 'kvs',
  SOZIALLEISTUNGEN: 'soz',
  FALLBACK_DEV: 'fb-dev',
  FALLBACK_TEST: 'fb-test',
  FALLBACK_PROD: 'fb-prod',
}

export {
  ENTRY_POINTS,
}
