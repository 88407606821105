// @flow
export default {
  brandName: 'Das Recht der Arbeit',
  paths: {
    overview: '/',
    archive: '/archiv',
    order: '/bestellen',
    about: '/ueber',
    impressum: '/impressum',
    privacypolicy: 'https://wien.arbeiterkammer.at/Datenschutz.html',
    search: '/suche',
    article: '/a',
    magazine: '/h',
    issue: '/i',
    list: '/l',
    content: '/c',
    link: '/link',
    probeabo: '/probeabo',
    notFound: '/404-not-found',
  },
  signalsPool: 'drda',
}
