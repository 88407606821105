/* eslint-disable */
import getInitialState from '../utils/getInitialState.js'

export const POOL_DRDA = 'oegbv-drda'
export const POOL_SOZ = 'oegbv-sozialleistungen'

function prepareIdqsa(pool) {
  const {env} = getInitialState()

  // set the pool identifier
  idqsa('id', `${pool}-${env}`)

  // disable dryRunMode in deployed environments
  idqsa('dryRunMode', (env !== 'prod' && env !== 'test' && env !== 'alpha'))

  // debugging
  if (env === 'localhost') {
    idqsa('debugSend', true)
  }
}

export function getClientInfo() {
  return idqsa('clientInfo')
}

export function getClientInfoAsRequestParamString(previousSearchId?: string) {
  const clientInfo = getClientInfo()
  if (!clientInfo) {
    return ''
  }
  const queryParams = {}
  queryParams['session-id'] = clientInfo.sessionId
  queryParams['tab-id'] = clientInfo.tabId
  queryParams['device-id'] = clientInfo.deviceId
  queryParams['user-id'] = clientInfo.userId || ''
  queryParams['all-signals'] = String(clientInfo.allSignals)
  queryParams['display-height'] = String(clientInfo.displayHeight)
  queryParams['display-width'] = String(clientInfo.displayWidth)

  if (previousSearchId && previousSearchId.length > 0) {
    queryParams['previous-search-id'] = previousSearchId
  }

  let keys = Object.keys(queryParams);
  let result = ''
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    result += `&${key}=${encodeURIComponent(queryParams[key])}`
  }
  return result
}

export function sendSelectSignal(pool, resultItemId, searchId) {
  prepareIdqsa(pool)
  idqsa('selectSignal', {resultItemId, searchId})
}

export function sendReadSignal(pool, resultItemId) {
  prepareIdqsa(pool)
  idqsa('readSignal', {resultItemId})
}

export function trackReadSignal(pool, resultItemId, element): void {
  prepareIdqsa(pool)
  idqsa('trackReadSignal', {resultItemId, element})
}

export function endTrackReadSignal(pool): void {
  prepareIdqsa(pool)
  idqsa('endTrackReadSignal')
}
