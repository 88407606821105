// @flow
export default {
  brandName: 'Sozialleistungen',
  baseUrl: 'https://www.sozialleistungen.at',
  paths: {
    overview: '/',
    book: '/buch',
    search: '/suche',
    webc: '/c',
    uie: '/__uie',
    notFound: '/404-not-found',
    spid: '/sozialpolitik-in-diskussion',
    spid_alle_baende: '/sozialpolitik-in-diskussion/alle-baende',
    pibg: '/in-bester-gesellschaft',
    spid_ueber_die_reihe: '/sozialpolitik-in-diskussion/ueber-die-reihe',
  },
  mappings: {
    sozialstaat: 'SL8096519',
    ansprueche: 'SL8096525',
    buch: 'SL8325663',
    publikationen: 'SL8096524',
    spidAbout: 'SL3597472',
  },
  signalsPool: 'sozialleistungen',
}
