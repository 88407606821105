// @flow
import type {SozAction} from '../types/SozActions'
import type {SozState} from '../types/SozState'

const initialState: SozState = {
  bookStructure: null,
  bookStructureIsLoading: false,
  bookStructureError: null,

  bookPara: null,
  bookParaIsLoading: false,
  selectedChapterId: null,

  autoSuggestIsLoading: false,
  autoSuggestResult: null,

  searchIsLoading: false,
  searchResult: null,
  searchQuery: null,

  tocInfoLoading: false,
  tocInfo: null,
  tocInfoError: null,

  webcDocumentIsLoading: false,
  webcDocument: null,
  webcDocumentError: null,
}

export default (state: SozState = initialState, action: SozAction) => {
  switch (action.type) {

    // Book Structure
    case 'SOZ_LOAD_BOOK_STRUCTURE':
      return {
        ...state,
        bookStructure: null,
        bookStructureIsLoading: true,
        bookStructureError: null,
      }
    case 'SOZ_LOAD_BOOK_STRUCTURE_SUCCESS':
      return {
        ...state,
        bookStructure: action.structure,
        bookStructureIsLoading: false,
      }
    case 'SOZ_LOAD_BOOK_STRUCTURE_ERROR':
      return {
        ...state,
        bookStructure: null,
        bookStructureIsLoading: false,
        bookStructureError: action.error,
      }

    // Book Para
    case 'SOZ_LOAD_BOOK_PARA':
      return {
        ...state,
        // bookPara: null,
        bookParaIsLoading: true,
      }
    case 'SOZ_LOAD_BOOK_PARA_SUCCESS':
      return {
        ...state,
        bookPara: action.para,
        bookParaIsLoading: false,
        selectedChapterId: action.selectedChapterId,
      }
    case 'SOZ_LOAD_BOOK_PARA_ERROR':
      return {
        ...state,
        bookPara: null,
        bookParaIsLoading: false,
        selectedChapterId: false,
      }

    // Autosuggest
    case 'SOZ_LOAD_AUTOSUGGEST':
      return {
        ...state,
        autoSuggestIsLoading: true,
      }
    case 'SOZ_LOAD_AUTOSUGGEST_SUCCESS':
      return {
        ...state,
        autoSuggestIsLoading: false,
        autoSuggestResult: action.result,
      }
    case 'SOZ_LOAD_AUTOSUGGEST_ERROR':
      return {
        ...state,
        autoSuggestIsLoading: false,
      }

    // Search
    case 'SOZ_LOAD_SEARCH':
      return {
        ...state,
        searchIsLoading: true,
        searchResult: null,
        searchQuery: action.query,
      }
    case 'SOZ_LOAD_SEARCH_SUCCESS':
      return {
        ...state,
        searchIsLoading: false,
        searchResult: action.result,
      }
    case 'SOZ_LOAD_SEARCH_ERROR':
      return {
        ...state,
        searchIsLoading: false,
      }

    // TOC
    case 'SOZ_LOAD_TOC_INFO':
      return {
        ...state,
        tocInfoIsLoading: true,
        tocInfo: null,
        tocInfoError: null,
      }
    case 'SOZ_LOAD_TOC_INFO_SUCCESS':
      return {
        ...state,
        tocInfoIsLoading: false,
        tocInfo: action.tocInfo,
      }
    case 'SOZ_LOAD_TOC_INFO_ERROR':
      return {
        ...state,
        tocInfoIsLoading: false,
        tocInfoError: action.error,
      }

    // WEBC
    case 'SOZ_LOAD_WEBC_DOCUMENT':
      return {
        ...state,
        webcDocumentIsLoading: true,
        webcDocument: null,
        webcDocumentError: null,
      }
    case 'SOZ_LOAD_WEBC_DOCUMENT_SUCCESS':
      return {
        ...state,
        webcDocumentIsLoading: false,
        webcDocument: action.document,
      }
    case 'SOZ_LOAD_WEBC_DOCUMENT_ERROR':
      return {
        ...state,
        webcDocumentIsLoading: false,
        webcDocumentError: action.error,
      }

    default:
      return state
  }
}
